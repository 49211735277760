import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import ContactForm from "src/components/ContactForm";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	Flex,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Grid,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as Icon from "react-icons/bi";
import * as Feather from "react-icons/fi";

const getData = graphql`
	{
		file(name: { eq: "clare-headshot" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 400
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
		logo: file(name: { eq: "Kingborough-Thrives-stack" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 220
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
	}
`;

const Contact = () => {
	const data = useStaticQuery(getData);
	const clare = getImage(data.file);
	const logo = getImage(data.logo);
	return (
		<Layout>
			<HeadSEO
				title="Get in Touch | Kingborough Thrives"
				slug="/contact/"
				description="Get in touch with your thoughts, suggestions, and questions about our campaign, our values, or our vision for a thriving Kingborough."
			/>
			<Box bg="gray.50" py="2rem" overflow="hidden">
				<Flex mx="auto" w="87.5vw" maxW="80rem" justify="center">
					<Box
						as={Link}
						to="/"
						alignSelf="center"
						maxH="100%"
						mr={["1rem", "1rem", "2rem"]}
					>
						<GatsbyImage image={logo} alt="Kingborough Thrives logo" />
					</Box>
					<VStack
						pl="2rem"
						align="flex-start"
						as="nav"
						alignSelf="center"
						spacing="1rem"
						// display={["none", "none", "flex"]}
					>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							href="/"
							_hover={{ bg: "blue.800" }}
						>
							Home
						</Heading>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							href="/about/"
							_hover={{ bg: "blue.800" }}
						>
							About Clare
						</Heading>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/kingborough-thrives/"
							_hover={{ bg: "blue.800" }}
						>
							Kingborough Thrives
						</Heading>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.700"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/contact/"
							_hover={{ bg: "blue.800" }}
						>
							Get in Touch
						</Heading>
					</VStack>
				</Flex>
			</Box>
			<Box bg="blue.600" color="white">
				<Box mx="auto" w="87.5vw" maxW="64rem" py="4rem">
					<Heading as="h1">Get in touch</Heading>
				</Box>
			</Box>
			<Box id="aboutclare" mx="auto" w="87.5vw" maxW="48rem" py="4rem">
				<VStack spacing="2rem" align="flex-start">
					<Text>
						Get in touch with your thoughts, suggestions, and questions about
						our campaign, our values, or our vision for a thriving Kingborough.
					</Text>
					<ContactForm />
				</VStack>
			</Box>
		</Layout>
	);
};

export default Contact;
